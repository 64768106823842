import React from "react"
import Layout from "../components/layout"
import SEO from "../components/seo"
import { FaLaughWink } from "react-icons/fa"

const NotFoundPage = () => {
  return (
    <React.Fragment>
      <SEO title="Oops, Not Found" />
      <Layout>
        <div className="container">
          <div
            className="d-flex flex-column justify-content-center align-items-center"
            style={{ minHeight: "90vh" }}
          >
            <FaLaughWink size={`10rem`} style={{ color: "#DDDDDD" }} />
            <div className="my-3" />
            <h1 className="display-4 text-center">
              Oops, we haven't made this page, yet...
            </h1>
            <div className="my-3" />
            <p className="text-center text-muted lead">
              If you're interested to see this page, why don't we discuss how we
              can work together?
            </p>
            <a
              href="mailto:sawir.ricardo@gmail.com"
              className="text-decoration-none"
            >
              Contact us!
            </a>
          </div>
        </div>
      </Layout>
    </React.Fragment>
  )
}

export default NotFoundPage
